export const paddocks = [
    { id: 'name', name: 'Paddock Name' },
    { id: 'total_area', name: 'Total Area' },
    // { id: 'grazeable_or_arable_area', name: 'Grazeable or Arable Area' },
    { id: 'land_use', name: 'Land Use' },
    // { id: 'crop_type', name: 'Crop Type', options: ['Crop Type 1', 'Crop Type 2', 'Crop Type 3',] },
    { id: 'colour_on_map', name: 'Colour on Map', options: ['Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Dark Blue', 'Purple'] },
    { id: 'plots_config' },
    { id: 'description', name: 'Description' },
]

export const field = [
    { id: 'name', name: 'Field Name' },
    { id: 'total_area', name: 'Total Area' },
    // { id: 'grazeable_or_arable_area', name: 'Grazeable or Arable Area' },
    // { id: 'land_use', name: 'Land Use', options: ['Land Use 1', 'Land Use 2', 'Land Use 3',] },
    // { id: 'crop_type', name: 'Crop Type', options: ['Crop Type 1', 'Crop Type 2', 'Crop Type 3',] },
    { id: 'colour_on_map', name: 'Colour on Map', options: ['Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Dark Blue', 'Purple'] },
    // { id: 'fielf', name: 'Field', options: ['Field 1 ', 'Field 2', 'Field 3',] },
    // { id: 'number_of_plots', name: 'Number of Plots', type: 'number' },
    { id: 'plots_config' },
    { id: 'description', name: 'Description' },
    // { id: 'crop', name: 'Crop', checkboxes: ['Cereals', 'Vegetables', 'Wheat', 'Fruits', 'Rice', 'Other', 'Corn'], hr: true },
    // { id: 'variety', name: 'Variety' },
]

export const building = [
    { id: 'name', name: 'Name of the Building / Infrastructure' },
    { id: 'type', name: 'Type of Building / Infrastructure', options: ['Building', 'Infrastructure',] },
    { id: 'colour_on_map', name: 'Colour on Map', options: ['Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Dark Blue', 'Purple'] },
    { id: 'description', name: 'Description' },
]

export const herd = [
    { id: 'name', name: 'Name of the animal' },
    { id: 'type', name: 'Type of animal', options: [], options_from: 'animal_type', show_field_when_fill: 'custom_type' },
    { id: 'custom_type', name: '', show_from_field_fill_eq: 'Custom animal' },
    { id: 'breed', name: 'Breed' },
    { id: 'status', name: 'Status', options: [], options_from: 'animal_status' },
    { id: 'sex', name: 'Sex', options: ['Male', 'Female', 'Neutered',] },
    { id: 'tag_number', name: 'Tag number' },
    { id: 'date_of_birth', name: 'Date of Birth', datepicker: true, },
    { id: 'birth_weight', name: 'Birth weight' },
    { id: 'raised_or_purchased', name: 'Raised or purchased', radio: ['Raised', 'Purchased',] },
    { id: 'disposal', name: 'Disposal', options: ['Sold', 'Decesead',] },
    { id: 'disposal_date', name: 'Disposal date', datepicker: true, },
    { id: 'description', name: 'Description' },
]

export const tool_equipment = [
    { id: 'type', name: 'Type', options: [], options_from: 'tool_equipment_type' },
    { id: 'name', name: 'Name' },
    { id: 'description', name: 'Description' },
        { id: 'position', name: 'Position', options: ['front', 'back'] },
        { id: 'brand', name: 'Brand' },
        { id: 'model', name: 'Model' },
        { id: 'working_width', name: 'Working width (meters)' },
        { id: 'power', name: 'Power (kw or hp)' },
        { id: 'production_year', name: 'Production year', yearpicker: true },
        { id: 'plate_number', name: 'Plate number' },
        { id: 'frame_number', name: 'Frame number' },
        { id: 'date_of_purchase', name: 'Date of purchase', datepicker: true, },
        { id: 'revision_date', name: 'Revision date', datepicker: true, },
        { id: 'date_of_technical_check', name: 'Date of technical check', datepicker: true, hr: true },
        { id: 'registration_document', name: 'Registration document', attach: true, },
        { id: 'insurance_document', name: 'Insurance document', attach: true, },
        { id: 'user_manual', name: 'User manual', attach: true, hr: true },
        { id: 'photo', name: 'Photo', photo_attach: true, },
]

export const soilConfig = [
    { id: 'soil_map', name: 'Soil map', dropzone: true, },
    { id: 'granulometry', name: 'Granulometry', options: [], options_from: 'granulometry' },
    { id: 'sand', name: '% Sand', input_group: 1 },
    { id: 'slit', name: '% Slit', input_group: 2 },
    { id: 'clay', name: '% Clay', input_group: 3 },
    { id: 'ph', name: 'pH' },
    { id: 'carbonates', name: 'Carbonates %' },
    { id: 'organic_substance', name: 'Organic substance %' },
    { id: 'organic_carbon', name: 'Organic carbon %' },
    { id: 'mineral_nitrogen', name: 'Mineral nitrogen' },
]



export const actionFields = {
    'activity-info':[
        { id: 'name_of_the_operation', name: 'Name of the operation' },
        { id: 'date_of_the_operation', name: 'Date of the operation', datepicker: true, hr: true },
        { id: 'name_of_the_person', name: 'Name of the person', options: [] },
        { id: 'visible_on_qrcode', name: 'Visible on QR code', checkbox: true, hr: true },
        // datepicker: true,
        { id: 'date_of_start_of_the_operation', name: 'Time of start of operation',  timepicker: true },
        { id: 'time_of_start_of_the_operation', time: true },
        // datepicker: true,
        { id: 'date_of_end_of_the_operation', name: 'Time of end of operation',  timepicker: true },
        { id: 'time_of_end_of_the_operation', time: true },
        { id: 'hours_worked', name: 'Hours worked' },
    ],
    'product-info':[
        { id: 'type_of_product',name:'Type of product'},
        { id: 'product_category',name:'Category'},
        { id: 'name_of_the_product', name: 'Name of the product' },
        { id: 'active_substance', name: 'Active substance' },
        { id: 'content_for_100g',name:'Content for 100g'}, 
        { id: 'dose_hectar', name: 'Dose / hectar' },
        { id: 'total_quantity', name: 'Total quantity' },
        { id: 'total_water_distributed', name: 'Total water distributed (liters)', hr: true },
        { id: 'adversity', name: 'Adversity/Pest',options:['Insects','Illnesses'] },
        { id: 'type_of_adversity', name: 'Type of adversity', hr: true },
        { id: 'reason_for_the_treatment', name: 'Reason for the treatment' },
    ],
    'notes-info':[
        { id: 'tool_equipment', name: 'Tool/Equipment', headlineTitle: true },
        { id: 'wind_indensity', name: 'Wind Indensity', options: ['Calm', 'Breeze', 'Windy'], headlineTitle: true },
        { id: 'weatcher_conditions', name: 'Weather conditions', options: ['Sunny', 'Cloudy', 'Rainy'], hr: true, headlineTitle: true },
        { id: 'notes_on_animals_and_plants', name: 'Notes on animals and plants', textarea: true },
        { id: 'notes_on_furrows', name: 'Notes on furrows', textarea: true, hr: true },
        { id: 'pest_noticed', name: 'Pest noticed', radio: ['Yes', 'No'], hr: true, headlineTitle: true },
        { id: 'irrigation_system_conditions', name: 'Irrigation system conditions', radio: ['Ok', 'Not ok'], headlineTitle: true },
        { id: 'irrigation_system_notes', textarea: true, hr: true },
        { id: 'other_notes', name: 'Other Notes', textarea: true },
    ]
}

export const actionHerd = [
    { id: 'name_of_the_operation', name: 'Name of the operation' },
    { id: 'type_of_vaccine_treatment', name: 'Type of vaccine / treatment', options: ['Type 1', 'Type 2', 'Type 3'] },
    { id: 'name_of_the_product', name: 'Name of the product' },
    { id: 'dosage', name: 'Dosage' },
    { id: 'date_of_the_treatment', name: 'Date of the treatment', datepicker: true, },
    { id: 'name_of_the_person', name: 'Name of the person', options: [], hr: true },
    { id: 'intervention_reason', name: 'Intervention reason' },

    // { id: 'name_of_the_operation', name: 'Name of the operation' },
    // { id: 'type_of_vaccine_treatment', name: 'Type of vaccine / treatment', options: ['Type 1', 'Type 2', 'Type 3'] },
    // { id: 'withholding_time', name: 'Withholding Time' },
    // { id: 'dosage', name: 'Dosage' },
    // { id: 'dosage_rate', name: 'Dosage Rate (per head or weight)' },
    // { id: 'date_of_the_treatment', name: 'Date of the treatment' },
    // { id: 'container_unit', name: 'Container unit' },
    // { id: 'container_size', name: 'Container size' },
    // { id: 'container_prize', name: 'Container prize' },
    // { id: 'expiry_date', name: 'Expiry date' },
    // { id: 'description', name: 'Description', textarea: true },
]

export const reportCategoriesList = [
    {
        title: 'Herd movement records',
        category: 'herd-movement'
    },
    {
        title: 'Feed records',
        category: 'feed'
    },
    // {
    //     title: 'Treatment records',
    //     category: 'treatment'
    // },
    {
        title: 'EDX analysis',
        category: 'edx-analysis'
    },
    {
        title: 'Treatments/action details',
        category: 'treatments-action-details'
    },
    {
        title: 'Field logbook',
        category: 'field-logbook'
    },
    {
        title: 'Field details',
        category: 'field',
        details: true,
    },
    {
        title: 'Paddock details',
        category: 'paddocks',
        details: true,
    },
    {
        title: 'Building details',
        category: 'building',
        details: true,
    },
    {
        title: 'IOT sensors',
        category: 'iot-sensors'
    },
    {
        title: 'Custom reports',
        category: 'custom'
    },
];

export const fieldsData = {
    crop: [
        "Other",
        "Absinthe",
        "Acacia Saligna",
        "Acca Sellowiana O Fejioia Sellowiana",
        "Achillea",
        "Agapanthus",
        "Agretto",
        "Agricultural Area Set Aside For The Production",
        "Agricultural Use Non - Buildings",
        "Alchechengi",
        "Alder",
        "Alder Napoletano",
        "Alfalfa",
        "Alfalfa (Sp. Medicago Sativa L. (Variety '))",
        "Almond",
        "Aloe",
        "Alpine Star",
        "Alstroemeria",
        "Altea",
        "Amamelide",
        "Amaranth",
        "American Potato (Batata)",
        "Anemone",
        "Angelica",
        "Aniseed Common",
        "Annual Poa",
        "Apple Tree",
        "Apricot",
        "Arable",
        "Aralia",
        "Arboretum Consociabile (With Grass Crops)",
        "Arboriculture",
        "Arnica",
        "Aronia Black",
        "Artemisia",
        "Artichoke",
        "Arundo Donax",
        "Ash Leafed O Southern",
        "Ash More",
        "Ash tree",
        "Asparagus",
        "Aspen tree",
        "Aspidistra",
        "Aubergine",
        "Avocado",
        "Azalea",
        "Azzeruolo",
        "Babaco",
        "Bamboo",
        "Bands Not Riparian Buffer",
        "Bands Riparian Buffer",
        "Bare ground",
        "Barley",
        "Barrel Of China (Miscanthus Sinensis)",
        "Basil",
        "Bean",
        "Bean of Egypt",
        "Bean Spain",
        "Beech Tree",
        "Beet - Rapa Red / Beet Da Costa",
        "Begonia",
        "Bergamot",
        "Birch",
        "Biricoccolo Susincocco",
        "Black Pine",
        "Black Poplar",
        "Blackberries",
        "Blackcurrant",
        "Blooming strips",
        "Borage",
        "Bosco",
        "Brassica Carinata (Ethiopian Cabbage)",
        "Broccoletto O Cime Di Rapa",
        "Broccoli",
        "Broom",
        "Buckwheat",
        "Buddleja",
        "Burdock",
        "Buttercup",
        "Cabbage",
        "Cabbage Rapa",
        "Calabash",
        "Carrubo",
        "Chard (Including Cheltenham Beet, Beet Red / Beet Da Costa)",
        "Chinese cabbage",
        "Cipolletta Onion Winter Or Bunching Onion",
        "Clover (Sp. Trifolium Hybridum L.)",
        "Coffee arabica",
        "Coffee robusta",
        "Corbezzolo",
        "Crambe",
        "Cranberry, Blueberry Blacks And Other Fruits Of The Genus 'Vaccinium'",
        "Cucumber",
        "Erbaio",
        "Evodia O Euodia Oalbero Or Tree Of Bees Honey",
        "Fave, Favino And Field Beans",
        "Festuca (Sp. Festuca Arundinacea Schreb.)",
        "Festuca (Sp. Festuca Rubra L.)",
        "Ficus Benjamina",
        "Gerbera",
        "Giant Bamboo",
        "Ginkgo Biloba",
        "Gold Bullion (Solidago Virga Aurea L.)",
        "Hackberry",
        "Hedges And Bands Wooded",
        "Holy basil",
        "Horehound Or Robbio",
        "Lima bean",
        "Lippia Citriodora Or Lemon Verbena Or Lemon Verbena (Aloysia Lemon Verbena)",
        "Lycium Barbarum (Goji)",
        "Margins Of Fields Seminabili",
        "Mizuna Or Brassica Rapa",
        "Mulberry",
        "Mustard Black",
        "Mustard Brown",
        "Navoni Rutabaga",
        "Okra Or Ocra Or Gombo",
        "Olivello Or Sea Buckthorn",
        "Pascolo Planted With Tree - Bushy Tara 20%",
        "Plants And Medical Herbs And Spices",
        "Raspberry",
        "Rhubarb",
        "Robbia Of Tintori",
        "Robinia",
        "Ryegrass (Sp. Lolium X Boucheanum Kunt.)",
        "Stevia Rebaudiana",
        "Strawberry",
        "Suger beet",
        "Timothy (Queue Topo) (Sp. Phleum Bertolini (Dc))",
        "Timothy Bulbous",
        "Tobacco",
        "Topinambur",
        "Tree Crops Subject To Permanent Ban On Fertilization And Treatment Plant Protection Along Streams",
        "Vegetables Full Field",
        "Verbena Officinal",
        "Viburnum",
        "White Currant (Gooseberry)",
    ],
    animal_type: [
        "Other",
        "Albaca",
        "Bees",
        "Bison",
        "Buffalo",
        "Butterflies",
        "Camel",
        "Cat",
        "Catfish",
        "Cattle",
        "Chicken",
        "Crickets",
        "Deer",
        "Dog",
        "Donkey",
        "Duck",
        "Elk",
        "Emu",
        "Fish",
        "Gayal",
        "Goat",
        "Goose",
        "Guineafowl",
        "Horse",
        "Llama",
        "Mealworms",
        "Mollusk",
        "Mule",
        "Muskox",
        "Ostrich",
        "Partridge",
        "Pheasant",
        "Pig",
        "Pigeon",
        "Pony",
        "Quail",
        "Rabbit",
        "Reindeer",
        "Rhea",
        "Salmon",
        "Sheep",
        "Shellfish",
        "Silkworms",
        "Swine",
        "Tilapia",
        "Trout",
        "Turkey",
        "Water buffalo",
        "Waxworms",
        "Yak",
        "Zebu",
    ],
    animal_status: [
        "Active",
        "Butchered",
        "Culled",
        "Deceased",
        "Dry",
        "Lactating",
        "Lost",
        "Off farm",
        "Quarantined",
        "Reference",
        "Sick",
        "Sold ",
        "Weaning",
    ],
    granulometry: [
        "Sand",
        "Loamy Sand",
        "Loam",
        "Sandy loam",
        "Silt Loam",
        "Sandy clay loam",
        "Clay loam",
        "Silty Clay Loam",
        "Sandy clay",
        "Silty Clay",
        "Clay",
    ],
    tool_equipment_type: [
        "Atomizer",
        "Baler",
        "Disk Harrow",
        "Spreader",
        "Harrow",
        "Hoeing Machine",
        "Irrigation",
        "Milling Machine",
        "Mower",
        "Other",
        "Plow",
        "Press Roll",
        "Seeder",
        "Shredder",
        "Sprayer",
        "Stocking",
        "Tank",
        "Tedder",
        "Tracked tractor",
        "Tractor",
        "Trailer",
        "Fixed Tank",
        "Mobile Tank",
        "Silos",
        "Seeder with Fertilizer and granulator",
        "Straddle two rows",
        "Grubber",
        "Roller",
        "Trimming Machine",
        "Harvesting Machine",
        "Subsoiler",
        "Tamping Machine",
        "Telescopic Arm",
        "Combined seed Drill",
        "Terragator",
        "Hedge Cutter",
    ]
}
export const typeOfActivity={
    'Primary':[
        'Defending',
        'Seeding',
        'Transplantation',
        'Feeding',
        'Irrigating',
        'Harvesting',
        'Seeding+feeding',
        'Transplantation+feeding',
    ],
    'Tileage of the field':[
        'Tilling',
        'Drilling',
        'Harrowing',
        'Leveling',
        'Milling',
        'Minimum tillage',
        'Rolling',
        'Soil ripping',
        'Ground processing',
        'Slicing',
        'Laser leveling',
        'Tamping',
        'Weeding',	
    ],
    'Crop interventions':[
        'Grafting',
        'Pruning',
        'Winter pruning',
        'Green pruning',
        'Wire arrangement',
        'Replanting',
        'Fruit thinning',
        'Leaf thinning',
        'Tying shoots',
        'Suckering',
        'Shoots topping',
        'Hole digging',
        'Logging',
        'Failed plants replacement',
        'Mulch ring'
    ],
    'Field mainteinance':[
        'Ditch cleaning and mainteinance',
        'Pruning residues removing',
        'Reaping',
        'Shredding',
        'Excerpt sarment',
        'Mechanical weeding',
        'Inter-row grass cutting',
        'Sparing shears',
        'Swathe',
        'Haying',
    ],
    'Installations':[
        'Hydraulic soil arrangement',
        'Scrub clearance',
        'Deforestation',
        'Grubbing',
        'Deep plowing',
        'Tiliage',
        'Stone clearing',
        'Stump removal',
        'Trenched',
        'Drainage Laying',
        'Drawing up irrigation hoses',
        'Hail/rain prevention system installation',
        'Laying cover sheets',
        'Ground mulching',
        'Irrigation logistics',
        'Loads',
    ],	
    'Herd':[
        'Stable cleaning',
        'Cattle feeding',
        'Animal bedding',
        'Cattle movement',
        'Cattle healtcare',
        'Manure movement',
    ],
    'Other':[
        'Observation',
        'Permitration',
        'Training',
        'Material transport',
        'Staff logistics',
        'Material movement',
        'Farm cleaning',
        'Other',
    ],
}
export const phenologicalStadium={
    'Arable crops':[
        'Germination',
        'Leaf development',
        'Tillering',
        'Rising',
        'Barrel',
        'Inflorescence emergence, earing',
        'Flowering',
        'Fruit development',
        'Maturation',
        'Senescence',
    ],
    'Tree crops':[
        'Vegetative recovery',
        'Sprout development',
        'Flowering',
        'Fruit set',
        'Swelling of the fruit',
        'Hardening of the core',
        'Veraison',
    ]	
}
export const typeOfProduct={
    'Fertilisers':[
        'Mineral fertiliser',
        'Organic fertiliser',
        'Other organic contributions',
        'Other mineral fertilisers',
        'Animal wastewater',
        'Annual fertiliser',
        'Bottom Fertiliser',
        'Foliar fertiliser',
    ],
    'Chemicals':[
        'Acaricide',
        'Aphicide',
        'Anti drift',
        'Antidote',
        'Sprout suppressant',
        'Antiheating',
        'Attractive',
        'Bather',
        'Coadjuvant',
        'Thinningner',
        'Pheromone',
        'Fitoregulator',
        'Geodisinfestant',
        'Repellent',
        'Synergizing',
        'Adjuvant',
        'Fungicide',
        'Herbicide',
        'Insecticide',
        'Molluscicide',
        'Biological medium',
        'Nematocide',
        'Growth substance',
    ],
    'Seed and plants':[
        'Seed',
        'Plants'
    ],
    'Other product':[
        'Fuel',
        'Oil',
        'Crop product',
        'Liquid',
        'Forage',
        'Container',
        'Coverage',
        'Piping',
        'Spare part',
        'Tool',
        'Animal',
    ]
}
export const typeOfAdversity={
    'Insects':[
        'Altica',
        'Foliar nocturnes',
        'Aphids',
        'Leaf miners',
        'Plutella',
        'Mites',
        'Other',
    ],
    'Illnesses':[
        'Pyitium',
        'Downy mildew / Bremia',
        'Fusarium',
        'Alternariasis',
        'Sclerotinia',
        'Botrytis',
        'Bacteriosis / Virosis',
        'Other',
    ]
}
export default {
    basicInfoConfig: {
        paddocks,
        field,
        building,
        herd,
        tool_equipment,
    },
    soilConfig,
    actionBasicInfoConfig: {
        fields: actionFields,
        herd: actionHerd
    },
    reportCategoriesList,
    fieldsData,
    typeOfActivity,
    phenologicalStadium,
    typeOfProduct,
    typeOfAdversity
}