<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header">
                <h1 class="heading-page-h1">{{ $translate('reports') }}</h1>
            </div>
            <div class="reports">
                <div class="reports__row">
                    <div class="reports__col" v-for="item in reportCategoriesList" :key="item.category">
                        <a class="link-box" @click="openReports(item)">{{ item.title }}</a>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { reportCategoriesList } from '@/config/farm';

export default {
    name: "Reports",
    data() {
        return {
            reportCategoriesList: reportCategoriesList,
        };
    },
    methods: {
        openReports(item) {
            this.$router.push({ path: "/reports/"+item.category });
        },
    },
};
</script>
